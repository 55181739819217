
.active-subscription{
    padding: 10px 15px;
    color: #ffffff;
}

.active-subscription.safe{
    background-color: #06D6A0;
}

.active-subscription.warning{
    background-color: #D8CC34;
}

.active-subscription.danger{
    background-color: #EC0B43;
}

.active-subscription-title{
    color: #ffffff;
    font-weight: bold;
}