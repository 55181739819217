@tailwind base;
@tailwind components;
@tailwind utilities;


/* @media screen and (min-width: 1200px){
    .sidebar-close-icon{
        display: none;
    }

}

.radio-button{
    font-weight: 600;
    padding-inline: 10px;
    padding-block: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: max-content;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid #eee;
    
}

.active-radio-button{
    border: 1px solid #665bff;
    background: #f2f1ff;
    color: #665bff;

} */