#no-subscription{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.sub_err{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:10px;
}

.sub_err-details{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.sub_err-type{
    letter-spacing: 3px;
    font-weight: 600;
    padding: 5px;
    background: rgb(251, 225, 233);
    border-radius: 3px;
    font-size: 10.24px;
    text-transform: uppercase;
    color: rgb(216, 30, 91);
}

.sub_err-title{
    font-size: 20px;
    display: block;
    font-weight: bold;
}

.sub_err-desc{
    font-size: 12.8px;
    text-align: center;
    width: 70%;
    margin: auto;
}

.current-plan{
    border: 1px solid rgb(216, 216, 227);
    z-index: 10;
    max-width: 500px;
    width: 100%;
    background: rgb(255, 255, 255);
    padding: 0px;
    border-radius: 5px;
}

.current_plan-header{
    padding: 20px 20px 0px;
    font-size: 16px;
    font-weight: 700;
}

.current_plan-details{
    padding: 15px 20px;
    display: grid;
    grid-template-columns: auto max-content;
    gap: 10px;
    -webkit-box-align: stretch;
    align-items: stretch;
}

.current_plan-price h3{
    font-size: 25px;
    font-weight: 900!important;
}
.current_plan-price span{
    font-size: 13px;
    font-weight: 400;
}

.current_plan-footer{
    background: rgb(250, 248, 249);
    margin: 0px;
    padding: 15px 20px;
}

.plans-btn{
    border: 1px solid rgb(0, 102, 245);
    color: rgb(0, 114, 187);
    z-index: 0;
    border-radius: 3px;
    padding: 10px 15px;
    transition: all 0.3s ease-out 0s;
    background-color: transparent;
}

.plans-btn:hover{
    background: rgba(0, 102, 245, 0.2)
}